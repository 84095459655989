/* 公共樣式 */
*,
p {
  margin: 0;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
body {
  font-size: 14px;
  font-family: 'microsoft yahei';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  /* 取消文字修飾 */
}
article,
section,
aside,
address,
nav,
header,
footer,
img {
  display: inline-block;
}
img {
  border: none;
}
li {
  list-style: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 全局的滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-bottom: 1px solid #7b7b7b;
}

/* 滚动条里面的小方块，能上下左右移动（取决于是垂直滚动条还是水平滚动条） */
::-webkit-scrollbar-thumb {
  margin-bottom: 10px;
  background: #949494;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
/* 滚动条的轨道（里面装有thumb）滚动槽 */
::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 0;
  -webkit-box-shadow: 0;
}

@primary-color: #52CCA3;