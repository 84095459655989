.ant-menu.ant-menu-dark {
  background: #25252a;
}

div.ant-card {
  border-radius: 11px;
}

ul.ant-pagination {
  display: flex;
  color: #86868b;
  text-align: right;
  margin-top: 24px;
  .ant-pagination-total-text {
    flex: 1 1;
    text-align: left;
  }
}

.ant-btn,
.ant-select-selector {
  border-radius: 4px !important;
}
.ant-select-selector,
.ant-input {
  border-radius: 6px;
}

.ant-modal-content {
  border-radius: 12px !important ;
  overflow: hidden;
}

// .ant-radio
.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fff;
  background: #52cca3;
  border-color: #52cca3;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
}
